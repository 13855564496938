import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import About from './pages/About'
import Home from './pages/Home'
import Ourwork from './pages/Ourwork'
import Services from './pages/Services'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import Blogs from './pages/Blogs'
import Contact from './pages/Contact'
import Orangebiochem from './pages/Orangebiochem'
import Galaxypharma from './pages/Galaxypharma'
import Goodrich from './pages/Goodrich'
import Gwala from './pages/Gwala'
import BestSolution from './pages/BestSolution'
import DigitalMarketingAGameOfDigitalPresence from './pages/DigitalMarketingAGameOfDigitalPresence'
import DigitalMarketingMythvsFacts from './pages/DigitalMarketingMythvsFacts'
import DigitalMarketingUnlockingTheSecrets from './pages/DigitalMarketingUnlockingTheSecrets'
import Dopefy from './pages/Dopefy'
import GauriShankara from './pages/GauriShankara'
import JurisSoul from './pages/JurisSoul'
import ShyamaHostels from './pages/ShyamaHostels'


function App() {
  return (
    <>
    <BrowserRouter>
    <Navbar/>
    <Routes>
      <Route path='/' element={<Home/>} />
      <Route path='/About' element={<About/>} />
      <Route path='/Blogs' element={<Blogs/>} />
      <Route path='/Contact' element={<Contact/>} />
      <Route path='/Ourwork' element={<Ourwork/>} />
      <Route path='/Services' element={<Services/>} />
      <Route path='/Orangebiochem' element={<Orangebiochem/>} />
      <Route path='/Galaxypharma' element={<Galaxypharma/>} />
      <Route path='/Goodrich' element={<Goodrich/>} />
      <Route path='/Gwala' element={<Gwala/>} />
      <Route path='/BestSolution' element={<BestSolution/>} />
      <Route path='/DigitalMarketingAGameOfDigitalPresence' element={<DigitalMarketingAGameOfDigitalPresence/>} />
      <Route path='/DigitalMarketingMythvsFacts' element={<DigitalMarketingMythvsFacts />} /> 
      <Route path='/DigitalMarketingUnlockingTheSecrets' element={<DigitalMarketingUnlockingTheSecrets />} />
      <Route path='/Dopefy' element={<Dopefy />} />
      <Route path='/GauriShankara' element={<GauriShankara />} />
      <Route path='/JurisSoul' element={<JurisSoul />} />
      <Route path='/ShyamaHostels' element={<ShyamaHostels />} />
    </Routes>
    <Footer/>
    </BrowserRouter>
    </>
  )
}

export default App

