import React from 'react'

function Goodrich() {
  return (
    <>
         <div className="wrapper">
    <div className="main">
            <section className="page1">
                <div className="page_container">
                    <img src="./images/gr-1.webp" width="100%" alt="" />
                </div>
            </section>

            <section className="page2">
                <div className="page_container">
                    <img src="./images/gr-2.webp" width="100%" alt="" />
                </div>
            </section>

            <section className="page3">
                <div className="page_container">
                    <img src="./images/gr-3.webp" width="100%" alt="" />
                </div>
            </section>

            <section className="page4">
                <div className="page_container">
                    <img src="./images/gr-4.webp" width="100%" alt="" />
                </div>
            </section>

            <section className="page5">
                <div className="page_container">
                    <img src="./images/gr-5.webp" width="100%" alt="" />
                </div>
            </section>

            <section className="page6">
                <div className="page_container">
                    <img src="./images/gr-6.webp" width="100%" alt="" />
                </div>
            </section>

            <section className="page7">
                <div className="page_container">
                    <img src="./images/gr-7.webp" width="100%" alt="" />
                </div>
            </section>

            <section className="page7">
                <div className="page_container">
                    <img src="./images/gr-8.webp" width="100%" alt="" />
                </div>
            </section>

          
    </div>
</div>
    </>
  )
}

export default Goodrich
