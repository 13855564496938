import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <>
          {/* info section */}
  <section className="info_section ">
    <div className="container">
      <div className="row">
        <div className="col-md-5">
          <div className="info_contact">
            <h5>Contact Us</h5>
            <div>
              <div className="img-box">
                <img
                  src="images/location-white.png"
                  width="18px"
                  alt="thedesignocreata, digitalmarketingagency"
                />
              </div>
              <p style={{ width: "70%" }}>
                Address: Building no. 218, 3rd floor, Scheme no. 78, next to
                Rexel India Pvt ltd, Part 2, Vijay Nagar, Indore, Madhya Pradesh
                452010
              </p>
            </div>
            <div>
              <div className="img-box">
                <img
                  src="images/telephone-white.png"
                  width="12px"
                  alt="thedesignocreata, digitalmarketingagency"
                />
              </div>
              <p>+91 6263082875</p>
            </div>
            <div>
              <div className="img-box">
                <img
                  src="images/envelope-white.png"
                  width="18px"
                  alt="thedesignocreata, digitalmarketingagency"
                />
              </div>
              <p>
                <Link to="mailto:tdca437@gmail.com" style={{ color: "white" }}>
                  tdca437@gmail.com
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="info_info">
            <h5>Quick Links</h5>
            <p>
              <Link to="/About" style={{ color: "white" }}>
                About Us
              </Link>
              <br />
              <br />
              <Link to="/Services" style={{ color: "white" }}>
                Our Services
              </Link>
              <br />
              <br />
              <Link to="/Ourwork" style={{ color: "white" }}>
                Our Work
              </Link>
              <br />
              <br />
              <Link to="/Blogs" style={{ color: "white" }}>
                Blog
              </Link>
              <br />
              <br />
              <Link to="/Contact" style={{ color: "white" }}>
                Contact Us
              </Link>
              <br />
              <br />
            </p>
          </div>
        </div>
        {/*Whatsapp*/}
        <p className="what_app">
          <Link
            to="https://api.whatsapp.com/send?phone=916263082875"
            target="_blank"
          >
            <span>Chat with us</span>
            <img
              src="https://cdn.shopaccino.com/zzanetti/images/wp-logo-289040-519746-521023-945140.png"
              alt="thedesignocreata, digitalmarketingagency"
              style={{ width: 38 }}
              className="img-responsive img-fluid"
            />
          </Link>
        </p>
        <div className="col-md-3">
          <div className="info_form ">
            <div className="social_box">
              <Link
                to="https://www.facebook.com/TheDesignoCreata/"
                target="_blank"
              >
                <img src="images/fb.png" alt="facebook" />
              </Link>
              <Link
                to="https://in.linkedin.com/in/the-designo-creata"
                target="_blank"
              >
                <img src="images/linkedin.png" alt="linkedin" />
              </Link>
              <Link
                to="https://www.instagram.com/thedesignocreata/"
                target="_blank"
              >
                <img src="images/instagram.png" alt="instagram" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* end info_section */}
  {/* footer section */}
  <section className="container-fluid footer_section">
    <p>
      © 2017 All Rights Reserved By
      <Link to="index.html">The Designo Creata</Link>
    </p>
  </section>
  {/* footer section */}
    </>
  )
}

export default Footer
