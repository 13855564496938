import React from 'react'

function Services() {
  return (
    <>
    
    {/* service section */}
    <section className="do_section layout_padding" style={{ paddingTop: 25 }}>
      <div className="container">
        <div className="heading_container">
          <h2>Our Services</h2>
          <br />
          <br />
        </div>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3">
          <div className="col">
            <div className="card card-hover">
              <img
                src="./images/d-44.png"
                className="card-img"
                alt="thedesignocreata, digitalmarketingagency"
              />
              <div className="card-body animatedown">
                <h5 className="card-title">
                  <b>Web Design</b>
                </h5>
                <p className="card-text">
                  Create engaging, visually stunning, and user-friendly websites
                  that reflect your brand identity, enhance your online presence,
                  and ensure a seamless user experience.
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card card-hover">
              <img
                src="./images/d-22.png"
                className="card-img"
                alt="thedesignocreata, digitalmarketingagency"
              />
              <div className="card-body animatedown">
                <h5 className="card-title">
                  <b>SEO</b>
                </h5>
                <p className="card-text">
                  Improve your website’s visibility and search engine rankings
                  with our advanced SEO techniques, driving organic traffic and
                  enhancing online presence
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card card-hover">
              <img
                src="./images/d-11.png"
                className="card-img"
                alt="thedesignocreata, digitalmarketingagency"
              />
              <div className="card-body animatedown">
                <h5 className="card-title">
                  <b>Digital Marketing</b>
                </h5>
                <p className="card-text">
                  Our services help you connect with your target audience
                  effectively. From strategic planning to execution, and tailored
                  services that drive engagement and boost conversions.
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3">
          <div className="col">
            <div className="card card-hover">
              <img
                src="./images/d-33.png"
                className="card-img"
                alt="thedesignocreata, digitalmarketingagency"
              />
              <div className="card-body animatedown">
                <h5 className="card-title">
                  <b>Social Media</b>
                </h5>
                <p className="card-text">
                  Build a thriving social media marketing presence with our expert
                  services, creating engaging content, fostering meaningful
                  interactions, and driving audience growth,
                  engagement,&nbsp;and&nbsp;loyalty.
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card card-hover">
              <img
                src="./images/d-66.png"
                className="card-img"
                alt="thedesignocreata, digitalmarketingagency"
              />
              <div className="card-body animatedown">
                <h5 className="card-title">
                  <b>Graphic Designing</b>
                </h5>
                <p className="card-text">
                  Translate your brand vision into captivating graphic elements,
                  including logos, brochures, presentations, and social media
                  graphics that leave a lasting impression.
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card card-hover">
              <img
                src="./images/d-77.png"
                className="card-img"
                alt="thedesignocreata, digitalmarketingagency"
              />
              <div className="card-body animatedown">
                <h5 className="card-title">
                  <b>Package Designing</b>
                </h5>
                <p className="card-text">
                  Design stunning product packaging that not only elevates your
                  brand identity but also strategically communicates your
                  product's value proposition and drives sales on store shelves.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* end do section */}
  </>
  
  )
}

export default Services
