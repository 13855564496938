import React from 'react'
import { Link } from 'react-router-dom'

function Ourwork() {
  return (
    <>
    <div className="gallery">
    <Link to="/Orangebiochem"><div className="gallery-item"><img src="./images/Orange-Biochem.gif" alt="thedesignocreata, digitalmarketingagency"/></div></Link>
    <Link to="/Galaxypharma"><div className="gallery-item"><img src="./images/Galaxy-Pharma.gif" alt="thedesignocreata, digitalmarketingagency"/></div></Link>
    <Link to="/Goodrich"><div className="gallery-item"><img src="./images/Good-Reech-Recovered.gif" alt="thedesignocreata, digitalmarketingagency"/></div></Link>
    <Link to="/Gwala"><div className="gallery-item"><img src="./images/gwala.gif" alt="thedesignocreata, digitalmarketingagency"/></div></Link>
    <Link to="/GauriShankara"><div className="gallery-item"><img src="./images/gouri-shankar.gif" alt="thedesignocreata, digitalmarketingagency"/></div></Link>
    <Link to="#"><div className="gallery-item"><img src="./images/dogggy.gif" alt="thedesignocreata, digitalmarketingagency"/></div></Link>
    <Link to="/Dopefy"><div className="gallery-item"><img src="./images/dopefy.gif" alt="thedesignocreata, digitalmarketingagency"/></div></Link>
    <Link to="/Jurissoul"><div className="gallery-item"><img src="./images/juris soul logo.gif" alt="thedesignocreata, digitalmarketingagency"/></div></Link>
    <Link to="/Bestsolution"><div className="gallery-item"><img src="./images/best-solution-gif.gif" alt="thedesignocreata, digitalmarketingagency"/></div></Link>
    <Link to="/ShyamaHostels"><div className="gallery-item"><img src="./images/shyama logo gif.gif" alt="thedesignocreata, digitalmarketingagency"/></div></Link>
  </div>
    </>
  )
}

export default Ourwork
