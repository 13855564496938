import React from 'react'
import Testimonials from '../components/Testimonials'
import Ourwork from '../components/Ourwork'
import ContactForm from '../components/ContactForm'
import { Link } from 'react-router-dom'
import CursorText from '../components/CursorText'

function Index() {

  
  return (
    <>

      <div className="header_div">
        {/* header section strats */}
        <header className="header_section">
          <div className="hero-video-container">
            <div className="hero-video">
              <img src="./images/graph.gif" alt="thedesignocreata" width="100%" />
            </div>
          </div>
        </header>

        {/* end header section */}
        {/* slider section */}
        <section className=" slider_section position-relative">
          <div className="container occupation-text">
            <div className="detail-box">
              <div>
                <h2>Hello! It's me...</h2>
                <h1>
                  <span style={{ color: "#e1dc2b" }}>THE</span>{" "}
                  <span style={{ color: "#55be93" }}>DESIGNO</span>{" "}
                  <span style={{ color: "#55be93" }}>CREATA</span>
                </h1>
                <span>We serve you... </span>
                <br />
                {/* <span id="occupation" className="occupation">
              {"Digital Marketing"}
            </span>
            <span className="cursor" /> */}
                <CursorText/>
              </div>
            </div>
          </div>
        </section>
        {/* end slider section */}
      </div>
      {/* do section */}
      <section className="do_section layout_padding">
        <div className="container fadeInUp-animation">
          <div className="heading_container">
            <h2>Our Services</h2>
         
            <p />
          </div>
          <div className="do_container">
            <div className="box">
              <div className="img-box">
                <Link to='/services'>
                  <img
                    src="images/d-4.png"
                    alt="thedesignocreata, digitalmarketingagency"
                  />
                </Link>
              </div>
              <div className="detail-box">
                <h6>Web Design</h6>
              </div>
            </div>
            <div className="box">
              <div className="img-box">
                <Link to='/services'>
                  <img
                    src="images/d-2.png"
                    alt="thedesignocreata, digitalmarketingagency"
                  />
                </Link>
              </div>
              <div className="detail-box">
                <h6>SEO</h6>
              </div>
            </div>
            <div className="box">
              <div className="img-box">
                <Link to='/services'>
                  <img
                    src="images/d-3.png"
                    alt="thedesignocreata, digitalmarketingagency"
                  />
                </Link>
              </div>
              <div className="detail-box">
                <h6>Social Media</h6>
              </div>
            </div>
            <div className="box">
              <div className="img-box">
                <Link to='/services'>
                  <img
                    src="images/d-6.png"
                    alt="thedesignocreata, digitalmarketingagency"
                  />
                </Link>
              </div>
              <div className="detail-box">
                <h6>Graphic Designing</h6>
              </div>
            </div>
            <div className="box">
              <div className="img-box">
                <Link to='/services'>
                  <img
                    src="images/d-1.png"
                    alt="thedesignocreata, digitalmarketingagency"
                  />
                </Link>
              </div>
              <div className="detail-box">
                <h6>Digital Marketing</h6>
              </div>
            </div>
            <div className="box">
              <div className="img-box">
                <Link to='/services'>
                  <img
                    src="images/d-7.png"
                    alt="thedesignocreata, digitalmarketingagency"
                  />
                </Link>
              </div>
              <div className="detail-box">
                <h6>Package Designing</h6>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Bole Toh*/}
      <section className="image-container">
        <img
          src="./images/bole toh.webp"
          alt="thedesignocreata, digitalmarketingagency"
          className="image-responsive"
        />
      </section>
      {/*End Bole Toh*/}
      {/* end do section */}

      <section class="work">
        <div class="heading_container">
          <h2>
            Our Work
          </h2>
        </div>
        <Ourwork />
      </section>
      <Testimonials />

      {/* target section */}
      {/*Our Clients */}
      <section className="target_section layout_padding">
        <div className="heading_container">
          <h2>Our Clients</h2>
        </div>
        <div className="containerclient">
          <img
            className="itemclient"
            src="./images/cl-bake and bite.png"
            alt="thedesignocreata, digitalmarketingagency"
          />
          <img
            className="itemclient"
            src="./images/cl-best solution.png"
            alt="thedesignocreata, digitalmarketingagency"
          />
          <img
            className="itemclient"
            src="./images/cl-galaxy pharma.png"
            alt="thedesignocreata, digitalmarketingagency"
          />
          <img
            className="itemclient"
            src="./images/cl-goodrich.webp"
            alt="thedesignocreata, digitalmarketingagency"
          />
          <img
            className="itemclient"
            src="./images/cl-govind masale.png"
            alt="thedesignocreata, digitalmarketingagency"
          />
          <img
            className="itemclient"
            src="./images/cl-orange biochem.png"
            alt="thedesignocreata, digitalmarketingagency"
          />
        </div>
        <div className="containerclient">
          <img
            className="itemclient"
            src="./images/cl-prachar.png"
            alt="thedesignocreata, digitalmarketingagency"
          />
          <img
            className="itemclient"
            src="./images/cl-shyama hostels.webp"
            alt="thedesignocreata, digitalmarketingagency"
          />
          <img
            className="itemclient"
            src="./images/cl-synagro.png"
            alt="thedesignocreata, digitalmarketingagency"
          />
          <img
            className="itemclient"
            src="./images/cl-tryambakam.png"
            alt="thedesignocreata, digitalmarketingagency"
          />
          <img
            className="itemclient"
            src="./images/cl-v-care.png"
            alt="thedesignocreata, digitalmarketingagency"
          />
          <img
            className="itemclient"
            src="./images/cl-zx paints.png"
            alt="thedesignocreata, digitalmarketingagency"
          />
        </div>
      </section>
      {/* end target section */}
      {/* contact section */}
      <section className="contact_section layout_padding">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto">
              <div className="heading_container">
                <h2> Request A Call Back </h2>
              </div>
              <ContactForm />
            </div>
            <div className="col-md-6 mx-auto">
              <iframe title='location'
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1839.514395702006!2d75.88666165456956!3d22.764312766765425!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3963034ee10999cd%3A0x6f1634555fd476da!2sThe%20Designo%20Creata!5e0!3m2!1sen!2sin!4v1716803431607!5m2!1sen!2sin"
                width="100%"
                height={450}
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>
        </div>
      </section>
      {/* end contact section */}

      {/* owl carousel script 
   
    */}
      {/* end owl carousel script */}
    </>
  )
  
}

export default Index
