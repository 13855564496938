import React from 'react'

function ContactForm() {
  return (
    <>
        <div className="contact-form">
            <form method="post" action="" name="contactform" id="contactform">
              <div>
                <input
                  type="text"
                  placeholder="Full Name"
                  name="Name"
                  required=""
                />
              </div>
              <div>
                <input
                  type="number"
                  placeholder="Phone Number"
                  name="Mobile Number"
                  required=""
                />
              </div>
              <div>
                <input
                  type="email"
                  placeholder="Email Address"
                  name="Email Id"
                  required=""
                />
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Message"
                  className="input_message"
                  name="Message"
                />
              </div>
              <div className="d-flex justify-content-center">
                <button type="submit" className="btn_on-hover">
                  {" "}
                  Send{" "}
                </button>
              </div>
              <div id="loading" style={{ display: "none" }}>
                Please Wait...
              </div>
            </form>
          </div>
    </>
  )
}

export default ContactForm
