import React from 'react'
import './css/GauriShankara.css'

function GauriShankara() {
    return (
        <>
            <section className="gauri-gs-background">
                <div className="gauri-row">
                    <div className="col-md-12">
                        <img src="./images/gs-1.webp" alt="" className="img-fluid gauri-img-width wow animate__animated animate__fadeIn" data-wow-delay="0.1s" />
                    </div>
                </div>
                <div className="gauri-row">
                    <div className="col-md-12">
                        <div className="gauri-text-content wow animate__animated animate__zoomIn" data-wow-delay="0.5s">
                            <h1 className="gauri-h1">Creating the Gauri Shankara Packaging Design</h1>
                            <p>The Gauri Shankara Tulsi Ashtgandha Kumkum Tilak project was an exciting endeavor where we aimed to blend traditional symbolism with modern packaging design. The challenge was to create a packaging design that not only preserved the sanctity and cultural significance of the product but also appealed to contemporary consumers.</p>
                        </div>
                        <div className="gauri-text-content wow animate__animated animate__zoomIn" data-wow-delay="0.5s">
                            <h1 className="gauri-h1">Client’s Challenge</h1>
                            <p>The primary challenge was to design a packaging that conveyed the spiritual and cultural essence of the product. The existing packaging was functional but lacked the visual appeal and symbolic representation that could resonate with the target audience.</p>
                        </div>
                    </div>
                </div>
                <div className="gauri-row">
                    <div className="col-md-12">
                        <img src="./images/gs-2.webp" alt="thedesignocreata, digitalmarketingagency"
                            className="img-fluid gauri-img-width wow animate__animated animate__zoomIn" data-wow-delay="0.3s" />
                    </div>
                </div>

                <div className="gauri-row">
                    <div className="col-md-6">
                        <img src="./images/gs-3.webp" alt="thedesignocreata, digitalmarketingagency"
                            className="img-fluid gauri-img-width wow animate__animated animate__fadeInLeft" data-wow-delay="0.3s" />
                    </div>
                    <div className="col-md-6">
                        <img src="./images/gs-4.webp" alt="thedesignocreata, digitalmarketingagency"
                            className="img-fluid gauri-img-width wow animate__animated animate__fadeInRight" data-wow-delay="0.3s" />
                    </div>
                </div>

                <div className="gauri-row">
                    <div className="col-md-12">
                        <div className="gauri-text-content wow animate__animated animate__zoomIn" data-wow-delay="0.1s">
                            <h1 className="gauri-h1">Our Approach</h1>
                            <p>To address this, we embarked on a comprehensive design journey, starting with deep research into the cultural and spiritual aspects of the product. We aimed to create a design that was both visually striking and deeply symbolic.</p>
                        </div>
                        <div className="gauri-text-content wow animate__animated animate__fadeInLeft" data-wow-delay="0.8s">
                            <h2 className="gauri-h2">Symbolism in Design</h2>
                            <p>The Shiv Tripund (three horizontal lines) and Shakti Bindi (red dot) were the central elements of the design, symbolizing the union of Shiva and Shakti. This combination was intended to convey spiritual harmony and power.</p>
                        </div>
                        <div className="gauri-text-content wow animate__animated animate__fadeInRight" data-wow-delay="0.8s">
                            <h2 className="gauri-h2">Color Palette and Imagery</h2>
                            <p>The use of vibrant colors and traditional motifs was crucial. The golden backdrop highlighted the purity and premium nature of the product, while the red and blue colors symbolized spirituality and trust.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="gauri-row">
                    <div className="col-md-12 first_section">
                        <img src="./images/gs-5.webp" alt="thedesignocreata, digitalmarketingagency"
                            className="img-fluid gauri-img-width wow animate__animated animate__zoomIn" data-wow-delay="0.1s" />
                    </div>
                </div>

                <div className="gauri-row">
                    <div className="col-md-12 first_section">
                        <img src="./images/gs-6.webp" alt="thedesignocreata, digitalmarketingagency"
                            className="img-fluid gauri-img-width rotate wow animate__animated animate__zoomIn" data-wow-delay="0.1s" />
                    </div>
                </div>
                <div className="gauri-row">
                    <div className="col-md-12 wow animate__animated animate__zoomIn">
                        <div className="gauri-text-content">
                            <h2 className="gauri-h2">Packaging Structure</h2>
                            <p>We designed two types of packaging to cater to different market needs – a box for retail shelves and a compact package for easy handling. Both designs prominently featured the Shiv Tripund and Shakti Bindi, ensuring brand consistency.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="gauri-row">
                    <div className="col-md-6">
                        <img src="./images/gs-7.webp" alt="thedesignocreata, digitalmarketingagency"
                            className="img-fluid gauri-img-width wow animate__animated animate__flipInX" data-wow-delay="0.1s" />
                    </div>
                    <div className="col-md-6 wow animate__animated animate__zoomIn">
                        <div className="gauri-text-content" style={{textAlign: 'left'}}>
                            <h2 className="gauri-h2">Results and Impact</h2>
                            <p>The redesigned packaging received an overwhelmingly positive response from both the client and the consumers. It not only enhanced the product's visibility on the shelves but also reinforced its cultural and spiritual essence. The symbolic design elements resonated deeply with the target audience, leading to increased brand loyalty and sales.
                            </p>
                        </div>
                        <div className="gauri-text-content" style={{textAlign: 'left'}}>
                            <h2 className="gauri-h2">Sales Impact</h2>
                            <p>The new packaging design contributed to a significant increase in sales, as it attracted a larger audience who were drawn to the product’s enhanced visual appeal and cultural relevance.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="gauri-row">
                    <div className="col-md-12">
                        <img src="./images/gs-8.webp" alt="thedesignocreata, digitalmarketingagency"
                            className="img-fluid gauri-img-width wow animate__animated animate__flipInX" data-wow-delay="0.1s" />
                    </div>
                </div>
            </section>
        </>
    )
}

export default GauriShankara
