import React from 'react'
import { Link } from 'react-router-dom'

function Navbar() {
  return (
    <>

      {/* header section strats */}
      <header className="header_section">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg custom_nav-container pt-3">
            <Link className="navbar-brand" to='/'>
              <span>
                <img
                  src="./images/TDC Logo transparent.webp"
                  alt="tdcLogo"
                  width="100px"
                  height="80px"
                />
              </span>
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <div className="d-flex ml-auto flex-column flex-lg-row align-items-center">
                <ul className="navbar-nav">
                  <li className="nav-item active">
                    <Link className="nav-link" to='/'>
                      Home <span className="sr-only">(current)</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to='/About'>

                      {" "}
                      About{" "}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to='/Services'>

                      {" "}
                      Our Services{" "}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to='/ourwork'>
                      {" "}
                      Our Work{" "}
                    </Link>
                  </li>
                  <li className="nav-item" />
                  <Link className="nav-link" to='/blogs'>
                    {" "}
                    Blog{" "}
                  </Link>
                  <li className="nav-item">
                    <Link className="nav-link" to='/contact'>
                      Contact us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </header>
      {/* end header section */}
    </>
  )
}

export default Navbar
