import React from 'react'

function Galaxypharma() {
  return (
    <>  
        <div className="wrapper">
    <div className="main">
            <section className="page1">
                <div className="page_container">
                    <img src="./images/gp-1.jpg" width="100%" alt="" />
                </div>
            </section>

            <section className="page2">
                <div className="page_container">
                    <img src="./images/gp-2.jpg" width="100%" alt="" />
                </div>
            </section>

            <section className="page3">
                <div className="page_container">
                    <img src="./images/gp-3.jpg" width="100%" alt="" />
                </div>
            </section>

            <section className="page4">
                <div className="page_container">
                    <img src="./images/gp-4.jpg" width="100%" alt="" />
                </div>
            </section>

            <section className="page5">
                <div className="page_container">
                    <img src="./images/gp-5.jpg" width="100%" alt="" />
                </div>
            </section>

            <section className="page6">
                <div className="page_container">
                    <img src="./images/gp-6.jpg" width="100%" alt="" />
                </div>
            </section>

            <section className="page7">
                <div className="page_container">
                    <img src="./images/gp-7.jpg" width="100%" alt="" />
                </div>
            </section>

            <section className="page8">
                <div className="page_container">
                    <img src="./images/gp-8.jpg" width="100%" alt="" />
                </div>
            </section>
            
            <section className="page8">
                <div className="page_container">
                    <img src="./images/gp-9.jpg" width="100%" alt="" />
                </div>
            </section>
            
            <section className="page8">
                <div className="page_container">
                    <img src="./images/gp-10.jpg" width="100%" alt="" />
                </div>
            </section>
            
            <section className="page8">
                <div className="page_container">
                    <img src="./images/gp-11.jpg" width="100%" alt="" />
                </div>
            </section>
            
  
    </div>
</div>
    </>
  )
}

export default Galaxypharma
