import React from "react";
import { Link } from "react-router-dom";

function About() {
  return (
   
      <>
        {/* who section */}
        <div className="desktop-only">
          <section
            className="who_section layout_padding"
            style={{
              backgroundImage: 'url("./images/about-web.webp")',
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
            }}
          >
            <div className="about_section">
              <div className="row">
                <div className="col-md-7">
                  <div className="detail-box">
                    <br />
                    <br />
                    <h1>About Us</h1>
                    <p>
                      Welcome to <span>The Designo Creata</span>, your premier
                      destination for comprehensive digital marketing services.
                      As a leading digital marketing company in India and with a
                      passion for innovation, creativity and commitment, we are
                      dedicated to transforming your digital presence and
                      driving impactful results through innovative marketing
                      strategies. <br />
                      <span>The Designo Creata</span> is a dynamic and
                      forward-thinking digital marketing agency that specialises
                      in a wide array of services designed to meet the unique
                      needs of businesses in today’s digital landscape. Our
                      expertise spans across website designing &amp;
                      development, SEO services, social media marketing, graphic
                      designing, and packaging designing. With a team of
                      creative minds and technical experts, we provide top-notch
                      customised solutions that help your brand stand out in the
                      crowded digital space.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <br />
          <br />
          <br />
        </div>
        <div className="desktop-only">
          <section className="slider">
            <div
              className="single-slider"
              style={{
                backgroundImage: 'url("./images/nitin-web.webp")',
                boxShadow: "0 0 15px 15px white inset",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-7">
                    <div className="text">
                      <h1>
                        NITIN GUPTA <br />
                        <span>Founder and Digital Alchemist</span>
                      </h1>
                      <br />
                      <div className="button">
                        <a href="tel:+916263082875" className="btn">
                          Contact Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        {/*/ End Slider Area */}
        {/* Start Schedule Area */}
        <div className="desktop-only">
          <section className="schedule">
            <div className="container">
              <div className="schedule-inner">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-12 ">
                    {/* single-schedule */}
                    <div className="single-schedule first">
                      <div className="inner">
                        <div className="icon">
                          <i className="fa fa-ambulance" />
                        </div>
                        <div className="single-content">
                          <p>
                            Nitin Gupta, the visionary founder of The Designo
                            Creata, brings over 14 years of experience in
                            design, print, and digital advertising to the helm.
                            After completing an MBA in Advertising &amp; Public
                            Relations, Nitin embarked on his journey in 2010.
                            Driven by a passion for innovation and dissatisfied
                            with conventional approaches, Nitin founded The
                            Designo Creata in 2017. His commitment to excellence
                            and relentless drive for client success have become
                            the agency's guiding principles. Nitin possesses a
                            unique ability to transform challenges into
                            opportunities, propelling The Designo Creata to new
                            heights in the dynamic digital landscape. Under his
                            leadership, the agency continues to redefine digital
                            marketing, offering innovative solutions that
                            empower clients to achieve their business
                            objectives.
                          </p>
                          <Link to="/Ourwork">
                            Check Our Work
                            <i className="fa fa-long-arrow-right" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        {/*/End Start schedule Area */}
        {/*Mobile View*/}
        <div className="mobile-only">
          <section className="who_section layout_padding">
            <div className="about_section_mobile">
              <div className="">
                <div>
                  <div className="content-box">
                    <h1>About Us</h1>
                    <p>
                      Welcome to <span>The Designo Creata</span>, your premier
                      destination for comprehensive digital marketing services.
                      As a leading digital marketing company in India and with a
                      passion for innovation, creativity and commitment, we are
                      dedicated to transforming your digital presence and
                      driving impactful results through innovative marketing
                      strategies. <br />
                      <span>The Designo Creata</span> is a dynamic and
                      forward-thinking digital marketing agency that specialises
                      in a wide array of services designed to meet the unique
                      needs of businesses in today’s digital landscape. Our
                      expertise spans across website designing &amp;
                      development, SEO services, social media marketing, graphic
                      designing, and packaging designing. With a team of
                      creative minds and technical experts, we provide top-notch
                      customised solutions that help your brand stand out in the
                      crowded digital space.
                    </p>
                    <div className="mobile-pic">
                      <img src="./images/about-mobile.webp" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="slider">
            <div className="single-slider">
              <div className="">
                <div className="row">
                  <div className="col-md-6 col-12">
                    <div>
                      <h1>
                        NITIN GUPTA <br />
                        <span>Founder and Digital Alchemist</span>
                      </h1>
                      <div className="button">
                        <a href="tel:+916263082875" className="btn">
                          Contact Now
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 col-12">
                    <div>
                      <img
                        src="./images/nitin-mobile.webp"
                        alt=""
                        className="nitin-image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="schedule">
            <div className="">
              <div className="schedule-inner">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-12 ">
                    {/* single-schedule */}
                    <div className="single-schedule first">
                      <div className="inner">
                        <div className="icon">
                          <i className="fa fa-ambulance" />
                        </div>
                        <div className="single-content">
                          <p>
                            Nitin Gupta, the visionary founder of The Designo
                            Creata, brings over 14 years of experience in
                            design, print, and digital advertising to the helm.
                            After completing an MBA in Advertising &amp; Public
                            Relations, Nitin embarked on his journey in 2010.
                            Driven by a passion for innovation and dissatisfied
                            with conventional approaches, Nitin founded The
                            Designo Creata in 2017. His commitment to excellence
                            and relentless drive for client success have become
                            the agency's guiding principles. Nitin possesses a
                            unique ability to transform challenges into
                            opportunities, propelling The Designo Creata to new
                            heights in the dynamic digital landscape. Under his
                            leadership, the agency continues to redefine digital
                            marketing, offering innovative solutions that
                            empower clients to achieve their business
                            objectives.
                          </p>
                          <Link to="/Ourwork">
                            Check Our Work
                            <i className="fa fa-long-arrow-right" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* end who section */}
      </>
 
  );
}

export default About;
