import React, { useEffect, useState } from 'react'

function CursorText() {

    const occupations = [
        'Digital Marketing Services',
        'Website Designing Services',
        'Graphic Designing Services'
      ];
    
      const [currentOccupation, setCurrentOccupation] = useState(0);
      const [currentChar, setCurrentChar] = useState(0);
      const [displayText, setDisplayText] = useState('');
      const [isTyping, setIsTyping] = useState(true);
    
      useEffect(() => {
        const typingTimeout = setTimeout(() => {
          if (isTyping) {
            if (currentChar < occupations[currentOccupation].length) {
              setDisplayText((prev) => prev + occupations[currentOccupation][currentChar]);
              setCurrentChar((prev) => prev + 1);
            } else {
              setIsTyping(false);
              setTimeout(() => {
                setIsTyping(false);
                setTimeout(() => {
                  setCurrentOccupation((prev) => (prev + 1) % occupations.length);
                  setCurrentChar(0);
                  setDisplayText('');
                  setIsTyping(true);
                }, 1000);
              }, 1000);
            }
          }
        }, isTyping ? 100 : 1000);
    
        return () => clearTimeout(typingTimeout);
      }, [isTyping, currentChar, currentOccupation, occupations]);
    

  return (
    <>
        <span id="occupation" className='occupation'>{displayText}</span>
        <span className="cursor" />
    </>
  )
}

export default CursorText
