import React from 'react'
import { Link } from 'react-router-dom'
import './css/DigitalMarketingUnlockingTheSecrets.css'

function DigitalMarketingUnlockingTheSecrets() {
    return (
        <>
            <section className="blog_section">
                <div className="">
                    <div>
                        <img
                            src="./images/blogs/Unlock the secrets of Digital Marketing.webp"
                            className="dmu-img-width"
                            alt="Digital Marketing"
                        />
                    </div>
                    <div className="container">
                        <br />
                        <h1>
                            <span style={{ fontWeight: 400 }}>
                                Unlocking the secrets of Digital Marketing
                            </span>
                        </h1>
                        <p>
                            <span style={{ fontWeight: 400 }}>
                                How many hours do you spend scrolling through your mobile phones and
                                you have enough knowledge of Digital Marketing?
                            </span>
                        </p>
                        <p>
                            <span style={{ fontWeight: 400 }}>
                                Have you ever wondered how just by searching for a pair of shoes, you
                                end up seeing the same thing all day long in your feed?
                            </span>
                        </p>
                        <p>
                            <span style={{ fontWeight: 400 }}>
                                Just go back and check your email box, you’ll be surprised to see the
                                love of Zomato or Swiggy towards you. In today’s world, this is called
                                marketing or we call it “Digital Marketing”
                            </span>
                        </p>
                        <h2>
                            <span style={{ fontWeight: 400 }}>What is Digital Marketing?</span>
                        </h2>
                        <p>
                            <span style={{ fontWeight: 400 }}>
                                In simple words, digital marketing means the type of marketing which
                                you do using the various digital channels such as social media, email,
                                blogs, videos, mobile phone, etc.
                            </span>
                        </p>
                        <p>
                            <span style={{ fontWeight: 400 }}>
                                Engaging customers digitally and persuading them to buy a particular
                                product is the game of digital marketing.
                            </span>
                        </p>
                        <h3>
                            <span style={{ fontWeight: 400 }}>
                                Various ways of Digital Marketing:
                            </span>
                        </h3>
                        <ul>
                            <li style={{ fontWeight: 400 }}>
                                <span style={{ fontWeight: 400 }}>Social Media Marketing (SMM)</span>
                            </li>
                            <li style={{ fontWeight: 400 }}>
                                <span style={{ fontWeight: 400 }}>Content Marketing</span>
                            </li>
                            <li style={{ fontWeight: 400 }}>
                                <span style={{ fontWeight: 400 }}>Affiliate Marketing</span>
                            </li>
                            <li style={{ fontWeight: 400 }}>
                                <span style={{ fontWeight: 400 }}>Influencer Marketing</span>
                            </li>
                            <li style={{ fontWeight: 400 }}>
                                <span style={{ fontWeight: 400 }}>Email Marketing</span>
                            </li>
                            <li style={{ fontWeight: 400 }}>
                                <span style={{ fontWeight: 400 }}>Video Marketing</span>
                            </li>
                            <li style={{ fontWeight: 400 }}>
                                <span style={{ fontWeight: 400 }}>WhatsApp Marketing</span>
                            </li>
                        </ul>
                        <p>
                            <strong>Social Media Marketing (SMM)</strong>
                        </p>
                        <p>
                            <span style={{ fontWeight: 400 }}>
                                In this, you use various social media platforms to promote your
                                products/services like -{" "}
                            </span>
                            <Link to="https://www.instagram.com/thedesignocreata/" target="_blank">
                                <span style={{ fontWeight: 400 }}>Instagram</span>
                            </Link>
                            <span style={{ fontWeight: 400 }}>, </span>
                            <Link
                                to="https://www.linkedin.com/in/the-designo-creata/"
                                target="_blank"
                            >
                                <span style={{ fontWeight: 400 }}>LinkedIn</span>
                            </Link>
                            <span style={{ fontWeight: 400 }}>, </span>
                            <Link to="https://www.facebook.com/TheDesignoCreata/" target="_blank">
                                <span style={{ fontWeight: 400 }}>Facebook</span>
                            </Link>
                            <span style={{ fontWeight: 400 }}>, YouTube, etc.</span>
                            <span style={{ fontWeight: 400 }}>
                                <br />
                            </span>
                            <span style={{ fontWeight: 400 }}>
                                Creating a Instagram page or YouTube channel is example of SMM
                            </span>
                        </p>
                        <p>
                            <strong>Content Marketing</strong>
                        </p>
                        <p>
                            <span style={{ fontWeight: 400 }}>
                                If you are reading this article then congrats in advance because you
                                are already into the content marketing
                            </span>
                            <span style={{ fontWeight: 400 }}>
                                <br />
                            </span>
                            <span style={{ fontWeight: 400 }}>
                                Content marketing is a means of digital marketing in which you engage
                                your respective customers through written content about your
                                product/service.
                            </span>
                            <span style={{ fontWeight: 400 }}>
                                <br />
                            </span>
                            <span style={{ fontWeight: 400 }}>
                                It can be in forms of - Blogs, Newsletter, E - books, Articles, etc.
                            </span>
                        </p>
                        <p>
                            <strong>Affiliate Marketing</strong>
                        </p>
                        <p>
                            <span style={{ fontWeight: 400 }}>
                                While going through your YouTube videos, have you ever heard
                                influencers saying, “Use this coupon code to buy a particular
                                product”. If “Yes”, then congrats again, you have already seen the
                                affiliate marketing’s glimpse.
                            </span>
                            <span style={{ fontWeight: 400 }}>
                                <br />
                            </span>
                            <span style={{ fontWeight: 400 }}>
                                Affiliate marketing is a type of digital marketing in which your
                                product/services are promoted by someone else, people use their coupon
                                codes, and in return you pay some part of commission to the promoter.
                            </span>
                        </p>
                        <p>
                            <strong>Influencer Marketing</strong>
                        </p>
                        <p>
                            <span style={{ fontWeight: 400 }}>
                                Here a quote by Mark Zuckerberg fits well - “
                            </span>
                            <em>
                                <span style={{ fontWeight: 400 }}>
                                    Nothing influences people more than a recommendation from a trusted
                                    friend
                                </span>
                            </em>
                            <span style={{ fontWeight: 400 }}>”</span>
                            <span style={{ fontWeight: 400 }}>
                                <br />
                            </span>
                            <span style={{ fontWeight: 400 }}>
                                In this type of digital marketing practice, you hire influencers to
                                promote your product/services. Because these influencers have fair
                                amount of impact on general public and people will listen to them due
                                to their
                            </span>
                            <span style={{ fontWeight: 400 }}>
                                <br />
                            </span>
                            <span style={{ fontWeight: 400 }}>
                                This is just like TV advertisements done by famous people. The only
                                difference is that here you hire local influencers who are more
                                impactful and people connect more to them.
                            </span>
                        </p>
                        <p>
                            <strong>Email Marketing</strong>
                        </p>
                        <p>
                            <span style={{ fontWeight: 400 }}>
                                Just take a pause and go back to your email box (maybe in the spam
                                section), have you seen Amazon or Zomato or any random mail telling
                                you to buy a particular product?
                            </span>
                            <span style={{ fontWeight: 400 }}>
                                <br />
                            </span>
                            <span style={{ fontWeight: 400 }}>Yes, this is email marketing!</span>
                            <span style={{ fontWeight: 400 }}>
                                <br />
                            </span>
                            <span style={{ fontWeight: 400 }}>
                                Email marketing is a method in which you send mails to your targeted
                                customers tailored according to their needs and persuade them to take
                                an action in order to buy your product.
                            </span>
                        </p>
                        <p>
                            <strong>Video Marketing</strong>
                        </p>
                        <p>
                            <span style={{ fontWeight: 400 }}>
                                Marketing through visual clips like Instagram reels, YouTube shorts,
                            </span>
                            <span style={{ fontWeight: 400 }}>
                                <br />
                            </span>
                            <span style={{ fontWeight: 400 }}>
                                Video marketing is more appealing and engaging.
                            </span>
                        </p>
                        <p>
                            <span style={{ fontWeight: 400 }}>WhatsApp Marketing</span>
                        </p>
                        <p>
                            <span style={{ fontWeight: 400 }}>
                                It’s a direct form of marketing, which can be done by using your
                                WhatsApp account. You can open a WhatsApp business account or create a
                                WhatsApp channel to reach out to your customers and cater their needs
                                and resolve their queries.
                            </span>
                            <span style={{ fontWeight: 400 }}>
                                <br />
                            </span>
                            <span style={{ fontWeight: 400 }}>
                                WhatsApp channels are like WhatsApp groups in which you create your
                                own.
                            </span>
                        </p>
                        <h2>
                            <span style={{ fontWeight: 400 }}>
                                Why is digital marketing gaining hype in modern times?
                            </span>
                        </h2>
                        <ul>
                            <li style={{ fontWeight: 400 }}>
                                <span style={{ fontWeight: 400 }}>Broader reach</span>
                            </li>
                            <li style={{ fontWeight: 400 }}>
                                <span style={{ fontWeight: 400 }}>Less expensive</span>
                            </li>
                            <li style={{ fontWeight: 400 }}>
                                <span style={{ fontWeight: 400 }}>
                                    Tailored for targeted audience
                                </span>
                            </li>
                            <li style={{ fontWeight: 400 }}>
                                <span style={{ fontWeight: 400 }}>Helps to analyze the growth</span>
                            </li>
                            <li style={{ fontWeight: 400 }}>
                                <span style={{ fontWeight: 400 }}>Better engagement</span>
                            </li>
                            <li style={{ fontWeight: 400 }}>
                                <span style={{ fontWeight: 400 }}>Quick results</span>
                            </li>
                        </ul>
                        <h3>
                            <span style={{ fontWeight: 400 }}>Did you know?</span>
                        </h3>
                        <p>
                            <span style={{ fontWeight: 400 }}>
                                According to various reports, around 59.9% to 68.3% of the world’s
                                population are using the internet As of July 2024, there were 5.45
                                billion internet users globally.
                            </span>
                        </p>
                        <p>
                            <span style={{ fontWeight: 400 }}>
                                <strong>
                                    India stands second with almost half of its population (49.15%) are
                                    online - nearly 759 million people
                                </strong>
                            </span>
                        </p>
                        <p>
                            <span style={{ fontWeight: 400 }}>
                                From the above data it is clearly visible that being present online is
                                vital for modern businesses.
                            </span>
                        </p>
                        <p>
                            <span style={{ fontWeight: 400 }}>
                                Unlike earlier people no longer turn the newspaper pages or go through
                                the hard copy of long brochures in order to get their needs catered.
                                They just have to click a button and in a blink, they get their
                                desired search results.
                            </span>
                        </p>
                        <p>
                            <em>
                                <span style={{ fontWeight: 400 }}>
                                    <strong>
                                        Steve Jobs once quoted that - “People don’t know what they want
                                        until you show it to them”
                                    </strong>
                                </span>
                            </em>
                        </p>
                        <p>
                            <span style={{ fontWeight: 400 }}>
                                So, here digital marketing helps you to generate a need for your
                                product by showing it to a larger public and persuading them to take
                                an action in order to buy that particular product.
                            </span>
                        </p>
                        <p>
                            <span style={{ fontWeight: 400 }}>
                                10 or 15 years back, have you ever imagined ordering a packet of rice
                                online by paying double the amount? But Blinkt is making it a reality.
                            </span>
                        </p>
                        <p>
                            <em>
                                <span style={{ fontWeight: 400 }}>
                                    “
                                    <strong>
                                        If your business is not on the internet, then your business will
                                        be out of business” - Bill Gates
                                    </strong>
                                </span>
                            </em>
                        </p>
                        <p>
                            <span style={{ fontWeight: 400 }}>
                                The above lines are so true, you can have a look around yourself and
                                think about businesses which were
                            </span>
                        </p>
                        <p>
                            <span style={{ fontWeight: 400 }}>
                                doing great once but suddenly vanished because they were unable to
                                cope up with the changing internet trends.
                            </span>
                        </p>
                        <p>
                            <span style={{ fontWeight: 400 }}>
                                Do you remember Nokia, Micromax? Once these were key mobile phone
                                players in Indian markets but overnight they vanished. Many experts
                                point out the reason for this was its weak online presence.
                            </span>
                        </p>
                        <p>
                            <span style={{ fontWeight: 400 }}>
                                On the other hand, Mamaearth, Sugar cosmetics, Boat like brands have
                                gone viral in a few years just because of its great online presence.
                            </span>
                        </p>
                        <p>
                            <span style={{ fontWeight: 400 }}>&nbsp;</span>
                        </p>
                        <p>
                            <span style={{ fontWeight: 400 }}>
                                Mamaearth’s influencer marketing strategy - there were times when
                                every single YouTuber became a fan of Mamaearth’s products and
                                persuaded the public to buy its super organic products.
                            </span>
                        </p>
                        <p>
                            <span style={{ fontWeight: 400 }}>
                                Overall, from above we can conclude that making a good digital
                                marketing strategy is oxygen for modern businesses and no business can
                                stand for a longer period without a digital presence.{" "}
                                <strong>Digital marketing agencies like “ </strong>
                            </span>
                            <Link to="/" target="_blank">
                                <span style={{ fontWeight: 400 }}>
                                    <strong>The Designo Creata</strong>
                                </span>
                            </Link>
                            <span style={{ fontWeight: 400 }}>
                                ”
                                <strong>
                                    , trying to provide digital marketing strategies to their clients.
                                </strong>
                            </span>
                        </p>
                    </div>
                </div>
            </section>

        </>
    )
}

export default DigitalMarketingUnlockingTheSecrets
