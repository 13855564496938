import React from 'react'

function Dopefy() {
    return (
        <>
            <div class="wrapper">
                <div class="main">
                    <section class="page1">
                        <div class="page_container">
                            <img src="./images/dopefy-1.webp" width="100%" alt="" />
                        </div>
                    </section>

                    <section class="page2">
                        <div class="page_container">
                            <img src="./images/dopefy-2.webp" width="100%" alt="" />
                        </div>
                    </section>

                    <section class="page3">
                        <div class="page_container">
                            <img src="./images/dopefy-3.webp" width="100%" alt="" />
                        </div>
                    </section>

                    <section class="page4">
                        <div class="page_container">
                            <img src="./images/dopefy-4.webp" width="100%" alt="" />
                        </div>
                    </section>

                    <section class="page5">
                        <div class="page_container">
                            <img src="./images/dopefy-5.webp" width="100%" alt="" />
                        </div>
                    </section>

                    <section class="page6">
                        <div class="page_container">
                            <img src="./images/dopefy-6.webp" width="100%" alt="" />
                        </div>
                    </section>

                    <section class="page7">
                        <div class="page_container">
                            <img src="./images/dopefy-7.webp" width="100%" alt="" />
                        </div>
                    </section>


                </div>
            </div> 
        </>
    )
}

export default Dopefy
