import React from 'react'
import './css/JurisSoul.css'

function JurisSoul() {
    return (
        <>
            <div class="container my-5 juris-container">
                <div class="row">
                    <div class="col-md-6 left-section">
                        <div class="">
                            <img src="./images/js-logo.webp" alt="thedesignocreata, digitalmarketingagency" class="img-fluid wow animate__animated animate__rubberBand" data-wow-delay="0.1s" />
                        </div>
                    </div>
                    <div class="col-md-6 right-section">
                        <div class="">
                            <img src="./images/js-letterhead.webp" alt="thedesignocreata, digitalmarketingagency" class="img-fluid wow animate__animated animate__zoomIn" data-wow-delay="0.1s" />

                        </div>
                    </div>
                </div>
            </div>
            <br /><br /><br />
            <div class="container juris-container">
                <div class="row">
                    <div class="col-md-6 left-section">
                        <div class="">
                            <img src="./images/juris soul - flyer.webp" alt="thedesignocreata, digitalmarketingagency" class="img-fluid wow animate__animated animate__zoomIn" data-wow-delay="0.1s" />
                        </div>
                    </div>
                    <div class="col-md-6 right-section">
                        <div class="juris-image-grid">
                            <img src="./images/js-1.webp" alt="thedesignocreata, digitalmarketingagency" class="img-fluid wow animate__animated animate__zoomIn" data-wow-delay="0.1s" />
                            <img src="./images/js-2.webp" alt="thedesignocreata, digitalmarketingagency" class="img-fluid wow animate__animated animate__zoomIn" data-wow-delay="0.3s" />
                            <img src="./images/js-3.webp" alt="thedesignocreata, digitalmarketingagency" class="img-fluid wow animate__animated animate__zoomIn" data-wow-delay="0.3s" />
                            <img src="./images/js-4.webp" alt="thedesignocreata, digitalmarketingagency" class="img-fluid wow animate__animated animate__zoomIn" data-wow-delay="0.3s" />
                        </div>
                    </div>
                </div>
            </div>
            <br /><br /><br />
            <div class="container juris-container">
                <div class="row">
                    <div class="col-md-6 left-section">
                        <div class="">
                            <img src="./images/js-5.webp" alt="thedesignocreata, digitalmarketingagency" class="img-fluid border wow animate__animated animate__swing" data-wow-delay="0.1s" />
                            <img src="./images/js-6.webp" alt="thedesignocreata, digitalmarketingagency" class="img-fluid border wow animate__animated animate__swing" data-wow-delay="0.1s" />
                        </div>
                    </div>
                    <div class="col-md-6 right-section">
                        <div class="">
                            <img src="./images/js-mobile.webp" alt="thedesignocreata, digitalmarketingagency" class="img-fluid wow animate__animated animate__zoomIn" data-wow-delay="0.1s" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default JurisSoul
