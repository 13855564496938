import React from 'react'

function BestSolution() {
  return (
    <>
        <div className="container">
    <div className="row">
      <div className="col-md-4 left-section">
        <div className="">
          <img src="./images/bs-1.webp" alt="thedesignocreata, digitalmarketingagency" className="img-fluid wow animate__animated animate__rotateInDownLeft" data-wow-delay="0.1s" />
          <img src="./images/bs-4.webp" alt="thedesignocreata, digitalmarketingagency" className="img-fluid wow animate__animated animate__rotateInDownLeft" data-wow-delay="0.3s" />
        </div>
      </div>
      <div className="col-md-4 right-section">
        <div className="">
          <img src="./images/bs-mobile.webp" alt="thedesignocreata, digitalmarketingagency" className="img-fluid wow animate__animated animate__fadeInUp" data-wow-delay="0.5s" /> 
        </div>
      </div>
      <div className="col-md-4 right-section">
        <div className="">
          <img src="./images/bs-2.webp" alt="thedesignocreata, digitalmarketingagency" className="img-fluid wow animate__animated animate__rotateInDownRight" data-wow-delay="0.1s" />
          <img src="./images/bs-3.webp" alt="thedesignocreata, digitalmarketingagency" className="img-fluid wow animate__animated animate__rotateInDownRight" data-wow-delay="0.3s" />
        </div>
      </div>
    </div>
  </div>
  <br/><br/><br/>
  <div className="container">
    <div className="row">
      <div className="col-md-6 left-section">
        <div className="">
          <img src="./images/bs-8.webp" alt="thedesignocreata, digitalmarketingagency" className="img-fluid wow animate__animated animate__flipInX" data-wow-delay="0.1s" />
        </div>
      </div>
      <div className="col-md-6 left-section">
        <div className="">
          <img src="./images/bs-9.webp" alt="thedesignocreata, digitalmarketingagency" className="img-fluid wow animate__animated animate__flipInX" data-wow-delay="0.1s" />
        </div>
      </div>
    </div>
  </div>
  <br/><br/><br/>
  <div className="container">
    <div className="row">
      <div className="col-md-4 left-section">
        <div className="">
          <img src="./images/bs-5.webp" alt="thedesignocreata, digitalmarketingagency" className="img-fluid wow animate__animated animate__fadeInLeft" data-wow-delay="0.2s" />
        </div>
      </div>
      <div className="col-md-4 left-section">
        <div className="">
          <img src="./images/bs-6.webp" alt="thedesignocreata, digitalmarketingagency" className="img-fluid wow animate__animated animate__bounceIn" data-wow-delay="0.3s" />
        </div>
      </div>
      <div className="col-md-4 left-section">
        <div className="">
          <img src="./images/bs-7.webp" alt="thedesignocreata, digitalmarketingagency" className="img-fluid wow animate__animated animate__fadeInRight" data-wow-delay="0.2s" />
        </div>
      </div>
    </div>
  </div>
    </>
  )
}

export default BestSolution
