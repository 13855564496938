import React from 'react'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'


export default function Testimonials() {
    
  return (
    <>
          {/* client section */}
          
  <div className="container">
    <div className="row">
      <div className="col-md-12">
      <OwlCarousel loop margin={1} items={1} autoplay>
          <div className="testimonial item">
            <div className="pic">
              <img
                src="images/c-1.webp"
                alt="thedesignocreata, digitalmarketingagency"
              />
            </div>
            <div className="testimonial-content">
              <h3 className="title">Mr. Raghvendra Parmar</h3>
              <span className="post">Juris Soul</span>
              <p className="description">
                Excellent marketing services for Juris Soul, our law coaching
                institute. Thanks to their innovative strategies, our student
                enrollments soared. Their team demonstrated true professionalism
                and delivered highly effective results. We've seen a significant
                boost in our visibility and reach within the legal education
                community. Their tailored approach to marketing legal education
                resonated well with aspiring law students.
              </p>
              <br />
              <span>
                <button className="testi_button">
                  <a href="./jurissoul.html" target="_blank">
                    Social Media
                  </a>
                </button>
              </span>
            </div>
          </div>
          <div className="testimonial item">
            <div className="pic">
              <img
                src="images/c-2.webp"
                alt="thedesignocreata, digitalmarketingagency"
              />
            </div>
            <div className="testimonial-content">
              <h3 className="title">Smt. Namrata Meena</h3>
              <span className="post">
                Owner of Shyama Girls &amp; Boys Hostels
              </span>
              <p className="description">
                The Designo Creata's campaign significantly increased our
                occupancy rates at Shyama Hostel. Their creative and efficient
                team delivered great results, making our partnership truly
                valuable. They don't make false promises - they follow through
                on their commitments. Thanks to their efforts, our new hostel at
                Vijaynagar, Indore, received the best marketing possible,
                providing real value for our investment. We're grateful for
                their impactful work in promoting Shyama Hostel.
              </p>
              <br />
              <span>
                <button className="testi_button">
                  <a href="https://shyamahostels.in/" target="_blank">
                    Shyama Hostels Website
                  </a>
                </button>
              </span>
              <span>
                <button className="testi_button">
                  <a href="./shyamahostels.html" target="_blank">
                    Social Media
                  </a>
                </button>
              </span>
            </div>
          </div>
          <div className="testimonial item">
            <div className="pic">
              <img
                src="images/c-3.webp"
                alt="thedesignocreata, digitalmarketingagency"
              />
            </div>
            <div className="testimonial-content">
              <h3 className="title">Dr. Devendra Dangi</h3>
              <span className="post">Dopefy, Satyology</span>
              <p className="description">
                As an NGO representative and motivational speaker, I was
                impressed by the team's ability to amplify our message. Their
                strategic approach significantly increased our reach and
                engagement. They understood my objectives and goals and created
                inspirational content, crafting campaigns that resonated deeply
                with my audience. Their efforts not only helped spread our
                message but also helped us connect with more people who needed
                our support and motivation.
              </p>
              <br />
              <span>
                <button className="testi_button">
                  <a href="./dopefy.html" target="_blank">
                    Dopefy
                  </a>
                </button>
              </span>
            </div>
          </div>
          <div className="testimonial item">
            <div className="pic">
              <img
                src="images/c-4.webp"
                alt="thedesignocreata, digitalmarketingagency"
              />
            </div>
            <div className="testimonial-content">
              <h3 className="title">Mr. Vipin Jain</h3>
              <span className="post">Orange Biochem</span>
              <p className="description">
                We at Orange Biochem were impressed by The Designo Creata's
                ability to capture the essence of our brand. Their creative
                campaigns not only resonated with pet owners, but also
                effectively communicated our commitment to innovative veterinary
                solutions. The Designo Creata's data-driven approach helped us
                reach new audiences and strengthen our position in the market.
                We're grateful for their partnership in promoting the well-being
                of animals everywhere.
              </p>
              <br />
              <span>
                <button className="testi_button">
                  <a href="./orange-biochem.html" target="_blank">
                    Orange Biochem
                  </a>
                </button>
              </span>
            </div>
          </div>
          <div className="testimonial item">
            <div className="pic">
              <img
                src="images/c-5.webp"
                alt="thedesignocreata, digitalmarketingagency"
              />
            </div>
            <div className="testimonial-content">
              <h3 className="title">Mr. Pushpendra Dangi</h3>
              <span className="post">Gwala</span>
              <p className="description">
                Their innovative strategies significantly boosted our brand
                visibility and customer trust. We've seen a remarkable increase
                in customer base and loyalty. They didn't just market our
                product; they communicated our passion for nurturing families
                with wholesome goodness. Their professionalism and dedication
                have been invaluable in our journey to revolutionise the dairy
                experience. They've truly helped us touch hearts and homes with
                GWALA's promise of purity.
              </p>
              <br />
              <span>
                <button className="testi_button">
                  <a href="./gwala.html" target="_blank">
                    Gwala
                  </a>
                </button>
              </span>
            </div>
          </div>
          </OwlCarousel>
      </div>
    </div>
  </div>
 
  {/* end client section */}
    
    </>
  )
}
