import React from 'react'
import ContactForm from '../components/ContactForm'

function Contact() {
  return (
    <>
         <section className="contact_section layout_padding">
    <div className="container">

      <div className="heading_container">
        <h2>
          Request A Call Back
        </h2>
      </div>
      <div className="">
        <div className="">
          <div className="row">
            <div className="col-md-9 mx-auto">
              <ContactForm/>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <iframe title='location'
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1839.514395702006!2d75.88666165456956!3d22.764312766765425!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3963034ee10999cd%3A0x6f1634555fd476da!2sThe%20Designo%20Creata!5e0!3m2!1sen!2sin!4v1716803431607!5m2!1sen!2sin"
          width="100%" height="450" style={{border:0}} allowFullScreen loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>
  </section>
    </>
  )
}

export default Contact
