import React from 'react'
import { Link } from 'react-router-dom'
import './css/DigitalMarketingMythvsFacts.css'

function DigitalMarketingMythvsFacts() {
    return (
        <>
            <section className="blog_section">

                <div className="desktop-only">
                    <img src="./images/blogs/digital marketing myth vs facts web.webp" className="dmm-img-width" alt="Digital Marketing" />
                </div>
                <div className="mobile-only">
                    <img src="./images/blogs/blog-3 thumbnail.webp" className="dmm-img-width" alt="Digital Marketing" />
                </div>
                <div className="container">
                    <br />
                    <h1 className="dmm-h1"><strong>Digital Marketing: Myth vs Facts</strong></h1>
                    <p className="dmm-p">Digital marketing is a rapidly growing field, today everyone needs it, be it a big company or a small
                        restaurant.</p>
                    <p className="dmm-p">But not everyone knows this field and due to this people have created many different myths about
                        digital marketing according to their understanding.</p>
                    <p className="dmm-p">So let's try to bust those myths today.</p>
                    <h2 className="dmm-h2"><span style={{ textDecoration: 'underline' }}><strong>Myths</strong></span></h2>
                    <div className="desktop-only">
                        <div className="row">
                            <div className="col-md-6">
                                <ul>
                                    <li>Digital marketing requires no skill</li><br />
                                    <li>It is similar to traditional marketing</li><br />
                                    <li>Digital marketing benefits only big firms</li><br />
                                    <li>It drives quick results</li><br />
                                    <li>Digital marketing is only sales-oriented</li><br />
                                    <li>A website is more than enough</li><br />
                                    <li>Digital marketing requires a huge budget</li><br />
                                    <li>SEO is a one-time effort</li><br />
                                    <li>Digital marketing is highly technical</li>
                                </ul>
                            </div>
                            <div className="col-md-6">
                                <img src="./images/blogs/Myths.webp" alt="" className="dmm-img-width" />
                            </div>
                        </div>
                    </div>
                    <div className="mobile-only">
                        <div className="row">
                            <div className="col-md-12">
                                <ul>
                                    <li>Digital marketing requires no skill</li>
                                    <li>It is similar to traditional marketing</li>
                                    <li>Digital marketing benefits only big firms</li>
                                    <li>It drives quick results</li>
                                    <li>Digital marketing is only sales-oriented</li>
                                    <li>A website is more than enough</li>
                                    <li>Digital marketing requires a huge budget</li>
                                    <li>SEO is a one-time effort</li>
                                    <li>Digital marketing is highly technical</li>
                                </ul>
                            </div>
                            <div className="">
                                <img src="./images/blogs/Myths.webp" alt="" className="dmm-img-width" />
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <br />
                        <h2 className="dmm-h2"><span style={{ textDecoration: 'underline' }}><strong>Reality Check</strong></span></h2>
                        <p className="dmm-p">Now we check the reality of the myths we listed above</p>
                        <h3 className="dmm-h3"><strong>Digital Marketing requires no skill</strong></h3>
                        <p className="dmm-p">Today's businesses think that digital marketing is just about posting photos or videos on their
                            online channels and their business will automatically grow.</p>
                        <p className="dmm-p"><strong className="dmm-facts">Fact </strong>- If businesses grew by posting photos or videos, probably all businesses would
                            be successful today. But it is not so.</p>
                        <p className="dmm-p">For a good digital presence, there needs to be a good digital marketing strategy, which requires
                            strategic planning, plotting, and creativity. For that, you need to hire some digital marketers or
                            learn the required skills on your own.</p>
                        <h3 className="dmm-h3"><strong>It is similar to traditional marketing</strong></h3>
                        <p className="dmm-p">Many of you might think that digital marketing only means selling. We can do that even without
                            digital marketing.</p>
                        <p className="dmm-p"><strong className="dmm-facts">Fact </strong>- No! Digital marketing does not only mean selling. Digital marketing helps
                            connect your business with people not only physically but also emotionally.</p>
                        <h3 className="dmm-h3"><strong>Digital Marketing benefits only big firms</strong></h3>
                        <p className="dmm-p">You might be thinking that we have just started our business and we will not get much profit from
                            digital marketing. First, we will build our identity offline and then once the business grows big we
                            will start online.</p>
                        <p className="dmm-p"><strong className="dmm-facts">Fact </strong>&ndash; In today&rsquo;s competitive times the sooner the business goes online
                            the better it is. It does not matter whether your business is</p>
                        <p className="dmm-p">small or big. If your business is small then digital marketing will help you in getting a good
                            foothold in the market right from the beginning.</p>
                        <h3 className="dmm-h3"><strong>It drives quick results</strong></h3>
                        <p className="dmm-p">Many businesses think that if they hire a digital marketing agency today, their business will be in
                            the headlines within a day or two.</p>
                        <p className="dmm-p"><strong className="dmm-facts">Fact </strong>- It is not like that. Just like it takes time to think about a business and
                            set it up, it also takes time to build its online identity. This requires consistent, focused, and
                            creative efforts.</p>
                        <h3 className="dmm-h3"><strong>Digital Marketing is only sales-oriented</strong></h3>
                        <p className="dmm-p">Wherever the name of marketing is mentioned, people think that it only means sales. But this is
                            wrong.</p>
                        <p className="dmm-p"><strong className="dmm-facts">Fact </strong>&ndash; Digital marketing does drive sales but it is not solely focused on
                            sales. It helps in brand building, customer engagement, goodwill and trust building, networking, and
                            analyzing the market as well.</p>
                        <h3 className="dmm-h3"><strong>A Website is more than enough</strong></h3>
                        <p className="dmm-p">There are thousands of such businesses in the market whose website you will find on the internet but
                            you will not see any growth.</p>
                        <p className="dmm-p"><strong className="dmm-facts">Fact </strong>- Having a website is necessary, but relying only on it is not enough. The
                            website should be regularly updated as per the industry trends so that traffic and engagement can
                            increase. Along with this, it is also necessary to be present on social media handles.</p>
                        <h3 className="dmm-h3"><strong>Digital Marketing requires a huge budget</strong></h3>
                        <p className="dmm-p">It is a myth of many people that digital marketing costs a lot of money and it is better to follow
                            traditional methods. But this is not true.</p>
                        <p className="dmm-p"><strong className="dmm-facts">Fact </strong>- For organic reach, you can get good growth in a very small amount through
                            digital marketing. You can start with social media handles initially by sharing basic information
                            about your business. Then you can slowly enhance it further.</p>
                        <h3 className="dmm-h3"><strong>SEO is a One-Time Effort</strong></h3>
                        <p className="dmm-p">If you are new in the digital marketing domain and you think that if you make your website SEO
                            friendly once, your website will start ranking immediately, then you are in a myth.</p>
                        <p className="dmm-p"><strong className="dmm-facts">Fact </strong>&ndash; To rank your website, you will have to update it consistently, read the
                            market trends and optimize the content as per the needs of the audience.</p>
                        <h3 className="dmm-h3"><strong>Digital Marketing is highly technical</strong></h3>
                        <p className="dmm-p">You must be thinking about how complex and technical digital marketing is. How can people with
                            non-technical backgrounds integrate it into their business?</p>
                        <p className="dmm-p"><strong className="dmm-facts">Fact </strong>&ndash; it's not rocket science and anybody can learn skill sets related to it.
                        </p><br />
                        <div className="desktop-only">
                            <img src="./images/blogs/Reality Check web.webp" className="dmm-img-width" alt="Digital Marketing" />
                        </div>
                        <div className="mobile-only">
                            <img src="./images/blogs/Reality Check mobile.webp" className="dmm-img-width" alt="Digital Marketing" />
                        </div><br /><br />
                        <p className="dmm-p">So these are the common myths regarding digital marketing that many people have, I hope this piece of
                            information has helped you in clearing doubts related to digital marketing.</p>
                        <p className="dmm-p">Now the myths have been busted, but how to do Digital Marketing for your business, are you worried
                            about this question? Then put your problems aside, schedule a call with <Link to="/"><strong>The Designo Creata (digital
                                marketing company in Indore)</strong></Link>, and get a solution to your problems.</p>
                        <p className="dmm-p">Hope you enjoyed reading this, stay tuned with us for more such content.</p>

                    </div>

                </div>

            </section>
        </>
    )
}

export default DigitalMarketingMythvsFacts
