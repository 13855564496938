import React from 'react'
import './css/ShyamaHostels.css'

function ShyamaHostels() {
    return (
        <>
            <div className="container shyama-container">
                <div className="row">
                    <div className="col-md-6 left-section">
                        <div className="">
                            <img src="./images/shyama Flyer.webp" alt="thedesignocreata, digitalmarketingagency" className="img-fluid wow animate__animated animate__zoomIn" data-wow-delay="0.1s" />
                        </div>
                    </div>
                    <div className="col-md-6 right-section">
                        <div className="shyama-image-grid">
                            <img src="./images/sh-1.webp" alt="thedesignocreata, digitalmarketingagency" className="img-fluid wow animate__animated animate__zoomIn" data-wow-delay="0.1s" />
                            <img src="./images/sh-2.webp" alt="thedesignocreata, digitalmarketingagency" className="img-fluid wow animate__animated animate__zoomIn" data-wow-delay="0.3s" />
                            <img src="./images/sh-3.webp" alt="thedesignocreata, digitalmarketingagency" className="img-fluid wow animate__animated animate__zoomIn" data-wow-delay="0.4s" />
                            <img src="./images/sh-4.webp" alt="thedesignocreata, digitalmarketingagency" className="img-fluid wow animate__animated animate__zoomIn" data-wow-delay="0.4s" />
                        </div>
                    </div>
                </div>
            </div>
            <br /><br /><br />
            <div className="container shyama-container">
                <div className="row">
                    <div className="col-md-6 left-section">
                        <div className="shyama-image-grid">
                            <img src="./images/sh-5.webp" alt="thedesignocreata, digitalmarketingagency" className="img-fluid wow animate__animated animate__zoomIn" data-wow-delay="0.1s" />
                            <img src="./images/sh-6.webp" alt="thedesignocreata, digitalmarketingagency" className="img-fluid wow animate__animated animate__zoomIn" data-wow-delay="0.3s" />
                            <img src="./images/sh-7.webp" alt="thedesignocreata, digitalmarketingagency" className="img-fluid wow animate__animated animate__zoomIn" data-wow-delay="0.4s" />
                            <img src="./images/sh-8.webp" alt="thedesignocreata, digitalmarketingagency" className="img-fluid wow animate__animated animate__zoomIn" data-wow-delay="0.4s" />
                        </div>
                    </div>
                    <div className="col-md-6 right-section">
                        <div className="shyama-image-grid">
                            <img src="./images/sh-mobile.webp" alt="thedesignocreata, digitalmarketingagency" className="img-fluid wow animate__animated animate__zoomIn" data-wow-delay="0.1s" />
                            <img src="./images/sh-mobile-2.webp" alt="thedesignocreata, digitalmarketingagency" className="img-fluid wow animate__animated animate__zoomIn" data-wow-delay="0.3s" />
                        </div>
                    </div>
                </div>
            </div>
            <br /><br /><br />
            <div className="container shyama-container">
                <div className="row">
                    <div className="col-md-6 left-section">
                        <div className="">
                            <img src="./images/sh-visiting-1.webp" alt="thedesignocreata, digitalmarketingagency" className="img-fluid border wow animate__animated animate__swing" data-wow-delay="0.1s" />
                        </div>
                    </div>
                    <div className="col-md-6 right-section">
                        <div className="">
                            <img src="./images/sh-visiting-2.webp" alt="thedesignocreata, digitalmarketingagency" className="img-fluid border wow animate__animated animate__swing" data-wow-delay="0.1s" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ShyamaHostels
