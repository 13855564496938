import React from 'react'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

function Ourwork() {
  return (
    <>
    <OwlCarousel loop items={3} autoplay>
        <div className="item">
            <Link to={'/Orangebiochem'}><img src="images/Orange-Biochem.gif" alt="" /></Link> 
        </div>
        <div className="item">
            <Link to={'/Galaxypharma'}><img src="images/Galaxy-Pharma.gif" alt="" /></Link>
        </div>
        <div className="item">
            <Link to={'/Goodrich'}> <img src="images/Good-Reech-Recovered.gif" alt="" /></Link>
        </div>
        <div className="item">
            <Link to={'/Gwala'}><img src="images/gwala.gif" alt="" /></Link>
        </div>
        <div className="item">
            <Link to={'/GauriShankara'}><img src="images/gouri-shankar.gif" alt="" /></Link> 
        </div>
        <div className="item">
            <Link to={'#'}><img src="images/dogggy.gif" alt="" /></Link> 
        </div>
        <div className="item">
            <Link to={'/Dopefy'}><img src="images/dopefy.gif" alt="" /></Link> 
        </div>
        <div className="item">
            <Link to={'/JurisSoul'}><img src="images/juris soul logo.gif" alt="" /></Link> 
        </div>
        <div className="item">
            <Link to={'/Bestsolution'}><img src="images/best-solution-gif.gif" alt="" /></Link> 
        </div>
        <div className="item">
            <Link to={'/ShyamaHostels'}><img src="images/shyama logo gif.gif" alt="" /></Link> 
        </div>
    </OwlCarousel>
    
    </>
  )
}

export default Ourwork
