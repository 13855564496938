import React from 'react'
import { Link } from 'react-router-dom'

function Blogs() {
  return (
    <>
        <section className="blog_section">
        <div className="container">
            <div className="heading_container">
                <h2>Blogs</h2>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="blog-card blog-card-blog">
                            <div className="blog-card-image">
                                <Link to="/DigitalMarketingUnlockingTheSecrets"> <img className="img" src="./images/blogs/blog-1 thumbnail.webp"/>
                                </Link>
                                <div className="ripple-cont"></div>
                            </div>
                            <div className="blog-table">
                                
                                <h4 className="blog-card-caption">
                                    <Link to="/DigitalMarketingUnlockingTheSecrets">Unlocking the secrets of Digital Marketing</Link>
                                </h4>
                                <p className="blog-card-description">How many hours you spend scrolling through your mobile phones and you have enough knowledge of Digital Marketing?</p>
                                <div className="ftr">
                                    <div className="author">
                                        <Link to="/DigitalMarketingUnlockingTheSecrets"> 
                                                 <span>Read More</span> </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="blog-card blog-card-blog">
                            <div className="blog-card-image">
                                <Link to="/DigitalMarketingAGameOfDigitalPresence"> <img className="img" src="./images/blogs/blog-2 thumbnail.webp"/>
                                </Link>
                                <div className="ripple-cont"></div>
                            </div>
                            <div className="blog-table">
                                
                                <h4 className="blog-card-caption">
                                    <Link to="/DigitalMarketingAGameOfDigitalPresence">Digital Presence: A Game of Digital Marketing</Link>
                                </h4>
                                <p className="blog-card-description">How many times have you taken the help of Google to search for a good cafe or a place?</p>
                                <div className="ftr">
                                    <div className="author">
                                        <Link to="/DigitalMarketingAGameOfDigitalPresence"> 
                                                 <span>Read More</span> </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="blog-card blog-card-blog">
                            <div className="blog-card-image">
                                <Link to="/DigitalMarketingMythvsFacts"> <img className="img" src="./images/blogs/blog-3 thumbnail.webp"/>
                                </Link>
                                <div className="ripple-cont"></div>
                            </div>
                            <div className="blog-table">
                                
                                <h4 className="blog-card-caption">
                                    <Link to="/DigitalMarketingMythvsFacts">Digital Marketing: Myths vs Facts</Link>
                                </h4>
                                <p className="blog-card-description">Digital marketing is a rapidly growing field, today everyone needs it, be it a big company or a small restaurant.</p>
                                <div className="ftr">
                                    <div className="author">
                                        <Link to="/DigitalMarketingMythvsFacts"> 
                                                 <span>Read More</span> </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </section>
    </>
  )
}

export default Blogs
