import React from 'react'

function Orangebiochem() {
  return (
    <>
        <div className="wrapper">
    <div className="main">
            <section className="page1">
                <div className="page_container">
                    <img src="./images/orange-biochem-1.webp" width="100%" alt=""/>
                </div>
            </section>

            <section className="page2">
                <div className="page_container">
                    <img src="./images/orange-biochem-2.webp" width="100%" alt=""/>
                </div>
            </section>

            <section className="page3">
                <div className="page_container">
                    <img src="./images/orange-biochem-3.webp" width="100%" alt=""/>
                </div>
            </section>

            <section className="page4">
                <div className="page_container">
                    <img src="./images/orange-biochem-4.webp" width="100%" alt=""/>
                </div>
            </section>

            <section className="page5">
                <div className="page_container">
                    <img src="./images/orange-biochem-5.webp" width="100%" alt=""/>
                </div>
            </section>

            <section className="page6">
                <div className="page_container">
                    <img src="./images/orange-biochem-6.webp" width="100%" alt=""/>
                </div>
            </section>

            <section className="page7">
                <div className="page_container">
                    <img src="./images/orange-biochem-7.webp" width="100%" alt=""/>
                </div>
            </section>

            <section className="page8">
                <div className="page_container">
                    <img src="./images/orange-biochem-8.webp" width="100%" alt=""/>
                </div>
            </section>
            
            <section className="page8">
                <div className="page_container">
                    <img src="./images/orange-biochem-9.webp" width="100%" alt=""/>
                </div>
            </section>
            
            <section className="page8">
                <div className="page_container">
                    <img src="./images/orange-biochem-10.webp" width="100%" alt=""/>
                </div>
            </section>
            
            <section className="page8">
                <div className="page_container">
                    <img src="./images/orange-biochem-11.webp" width="100%" alt=""/>
                </div>
            </section>
            
            <section className="page8">
                <div className="page_container">
                    <img src="./images/orange-biochem-12.webp" width="100%" alt=""/>
                </div>
            </section>
            <section className="page8">
                <div className="page_container">
                    <img src="./images/orange-biochem-13.webp" width="100%" alt=""/>
                </div>
            </section>
            <section className="page8">
                <div className="page_container">
                    <img src="./images/orange-biochem-14.webp" width="100%" alt=""/>
                </div>
            </section>
            <section className="page8">
                <div className="page_container">
                    <img src="./images/orange-biochem-15.webp" width="100%" alt=""/>
                </div>
            </section>
            <section className="page8">
                <div className="page_container">
                    <img src="./images/orange-biochem-16.webp" width="100%" alt=""/>
                </div>
            </section>
            <section className="page8">
                <div className="page_container">
                    <img src="./images/orange-biochem-17.webp" width="100%" alt=""/>
                </div>
            </section>
            <section className="page8">
                <div className="page_container">
                    <img src="./images/orange-biochem-18.webp" width="100%" alt=""/>
                </div>
            </section>
            <section className="page8">
                <div className="page_container">
                    <img src="./images/orange-biochem-19.webp" width="100%" alt=""/>
                </div>
            </section>
            <section className="page8">
                <div className="page_container">
                    <img src="./images/orange-biochem-20.webp" width="100%" alt=""/>
                </div>
            </section>
            <section className="page8">
                <div className="page_container">
                    <img src="./images/orange-biochem-21.webp" width="100%" alt=""/>
                </div>
            </section>
            <section className="page8">
                <div className="page_container">
                    <img src="./images/orange-biochem-22.webp" width="100%" alt=""/>
                </div>
            </section>
            <section className="page8">
                <div className="page_container">
                    <img src="./images/orange-biochem-23.webp" width="100%" alt=""/>
                </div>
            </section>
            <section className="page8">
                <div className="page_container">
                    <img src="./images/orange-biochem-24.webp" width="100%" alt=""/>
                </div>
            </section>
            <section className="page8">
                <div className="page_container">
                    <img src="./images/orange-biochem-25.webp" width="100%" alt=""/>
                </div>
            </section>
            <section className="page8">
                <div className="page_container">
                    <img src="./images/orange-biochem-26.webp" width="100%" alt=""/>
                </div>
            </section>
            <section className="page8">
                <div className="page_container">
                    <img src="./images/orange-biochem-27.webp" width="100%" alt=""/>
                </div>
            </section>
    </div>
</div>
    </>
  )
}

export default Orangebiochem
