import React from 'react'

function Gwala() {
  return (
    <>
          <section className='gwala'>
    <div className="row">
      <div className="col-md-12">
        <img src="./images/gwala-1.webp" alt="" className="img-fluid img-width wow animate__animated animate__fadeIn"/>
      </div>
    </div>
    <div className="row">
      <div className="col-md-6 first_section">
        <img src="./images/gwala-2.webp" alt="thedesignocreata, digitalmarketingagency"
          className="img-fluid img-width wow animate__animated animate__fadeInLeft" data-wow-delay="0.1s"/>
      </div>
      <div className="col-md-6 wow animate__animated animate__fadeInRight">
        <div className="text-content">
          <h1>Pure Vision, Fresh Identity: Redefining GWALA Dairy</h1>
          <p>At <span className="highlight">The Designo Creata</span>, we pride ourselves on our ability to transform
            brands, making them resonate with their audiences in meaningful ways. One of our recent and most impactful
            projects was the complete brand identity revamp for <span className="highlight">GWALA Dairy</span>, a brand that
            stands as a testament to purity, freshness and tradition.</p>
        </div>
      </div>
    </div>

    <div className="row">
      <div className="col-md-6 wow animate__animated animate__zoomIn">
        <div className="text-content">
          <h2>Our Brainstorming</h2>
          <p>
            After extensive research into the milk industry and engaging with milkmen and clients, we gained valuable
            insights into their needs and preferences. This understanding helped shape our strategy and creative
            approach.
          </p>
          <h2>The Logo</h2>
          <p>
            The logo features a man from a village wearing a turban and sporting a bold black mustache, evoking the
            image of a traditional milkman (Doodh Wala). This character is typically seen riding a splendor bike with
            two metal milk containers hanging on both sides. The l in the design of the word <span
              className="highlight">"GWALA"</span> incorporates splashed milk, connecting with the glass milk bottle, and
            emphasizing the brand’s commitment to real, pure milk. Below the name the line <span
              className="highlight">"GWALA Doodhwala,"</span> we highlighted the unique selling proposition (USP) to
            reinforce the brand’s promise.</p>
        </div>
      </div>
      <div className="col-md-6">
        <img src="./images/gwala-3.webp" alt="thedesignocreata, digitalmarketingagency"
          className="img-fluid img-width wow animate__animated animate__flipInX" data-wow-delay="0.1s"/>
      </div>
    </div>

    <div className="row">
      <div className="col-md-6 first_section">
        <img src="./images/gwala-4.webp" alt="thedesignocreata, digitalmarketingagency"
          className="img-fluid img-width wow animate__animated animate__flipInX" data-wow-delay="0.1s"/>
      </div>
      <div className="col-md-6 wow animate__animated animate__zoomIn">
        <div>
          <img src="./images/gwala-9.webp" alt="thedesignocreata, digitalmarketingagency"
            className="img-fluid img-width wow animate__animated animate__flipInX" data-wow-delay="0.1s"/>
        </div>
      </div>
    </div>

    <div className="row">
      <div className="col-md-6 first_section">
        <img src="./images/gwala-5.webp" alt="thedesignocreata, digitalmarketingagency"
          className="img-fluid img-width wow animate__animated animate__flipInX" data-wow-delay="0.1s"/>
      </div>
      <div className="col-md-6">
        <div className="text-content">
          <h2>Gwala Doodh Goanwala</h2>
          <p>
            The term <span className="highlight">"GWALA"</span> refers to a person who takes care of cows, both in ancient
            times and today. This name was chosen to honor the traditional role of the milkman. As GWALA Doodhwala, the
            name also reflects the village roots and the essential role of milk selling, embodying authenticity and
            trust.</p>
        </div>

      </div>
    </div>
    <div className="row">

      <div className="col-md-6 wow animate__animated animate__zoomIn">
        <div className="text-content">
          <h2>Purity, Freshness, Vibrancy: <span className="highlight">GWALA's Brand Rebirth</span></h2>
          <p>The white color of the bottle resonates with the purity of milk. We decided to show glass bottles, aligning
            with their commitment to a sustainable environment, free from plastic and polythene. The bottles are
            designed to be 90 percent filled, reflecting the actual product packaging.
            We crafted a compelling brand story that highlighted GWALA’s commitment to quality and natural goodness.
            This narrative was integrated across all touchpoints, ensuring a cohesive brand experience. The story of
            "bringing home the goodness" resonated with consumers, emphasizing the freshness and purity of GWALA's
            products delivered straight from the farm.
          </p>
        </div>
      </div>
      <div className="col-md-6">
        <div>
          <img src="./images/gwala-6.webp" alt="thedesignocreata, digitalmarketingagency"
            className="img-fluid img-width wow animate__animated animate__flipInX" data-wow-delay="0.1s"/>
        </div>
      </div>

    </div>
    <div className="row">
      <div className="col-md-12">
        <img src="./images/gwala-7.webp" alt="thedesignocreata, digitalmarketingagency"
          className="img-fluid img-width wow animate__animated animate__flipInX" data-wow-delay="0.1s"/>
      </div>
    </div>
  </section>
    </>
  )
}

export default Gwala
